import { App } from "@capacitor/app";
import { AppState } from "@capacitor/app";
import { isPlatform } from "@ionic/core";
import { IonAlert } from "@ionic/react";
import { Market } from "@ionic-native/market";
import { getAppInfo, getDeviceInfo } from "@src/appV2/lib";
import { Version } from "@src/lib/interface";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";

import { getMobileAppVersionsPostLogin, getMobileAppVersionsPreLogin } from "./api";
import { VersionUpdateOptions } from "./model";

// FIXME: Update this component to use version info from local device like src/appV2/Accounts/AppVersionInfo/AppVersionInfo.tsx
const AppVersion: FC<{ beforeLogin?: boolean }> = ({ beforeLogin }) => {
  const [isNewVersionAvailable, setVersionAvailable] = useState<boolean>(false);
  const [isDeprecated, setIsDeprecated] = useState<boolean>(true);

  const agentStage = localStorage.getItem("agentstage") ?? "";
  const agentmsa = localStorage.getItem("agentmsa") ?? "";

  useEffect(() => {
    const fetchVersion = async () => {
      const { version } = await getAppInfo();
      const { platform } = await getDeviceInfo();

      if (isEmpty<String>(version)) {
        return;
      }

      const options: VersionUpdateOptions = {
        appVersion: version,
        platform: platform,
        msa: agentmsa,
        status: agentStage,
      };

      try {
        const { versionInfo = null, latestMobileVersion = null } = beforeLogin
          ? await getMobileAppVersionsPreLogin(options)
          : await getMobileAppVersionsPostLogin(version, platform);

        if (versionInfo && latestMobileVersion) {
          checkAppUpdate(platform, latestMobileVersion, versionInfo);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchVersion();

    const onStateChange = (state: AppState) => {
      if (!state.isActive) {
        return;
      }
      fetchVersion();
    };

    App.addListener("appStateChange", onStateChange);
  }, []);

  const closeAlert = () => setVersionAvailable(false);

  const checkAppUpdate = (platform: string, latestMobileVersion: Version, versionInfo: Version) => {
    if (isEmpty(versionInfo) || isEmpty(latestMobileVersion)) {
      return;
    }

    if (latestMobileVersion.mobileVersion === versionInfo.mobileVersion) {
      return;
    }

    if (latestMobileVersion.published[platform]) {
      setIsDeprecated(versionInfo.deprecated);
      setVersionAvailable(true);
    }
  };

  const updateApp = () => {
    if (isPlatform(window, "ios")) {
      Market.open("id1472962791");
    }
    if (isPlatform(window, "android")) {
      Market.open("health.clipboard.worker");
    }
  };

  const cancelButton = [
    {
      text: "Later",
      role: "cancel",
    },
  ];

  return (
    <IonAlert
      isOpen={isNewVersionAvailable}
      onDidDismiss={closeAlert}
      backdropDismiss={false}
      header="Update Available"
      message={
        "A new version of the Clipboard Health app is available. Click Update and enjoy our new features!"
      }
      buttons={[
        ...(isDeprecated ? [] : cancelButton),
        {
          text: "Update",
          handler: updateApp,
        },
      ]}
    />
  );
};

export { AppVersion };
